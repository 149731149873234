import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosInstance from '../configs/axios';

const ticketUrl = '/user/ticket';

export interface TicketFileUpload {
  files?: FormData;
}

export interface ListTickets {
  status: number;
  tickets: [];
  pages: {
    page: number;
    per_page: number;
    total_pages: number;
  };
}

export const fileUpload = createAsyncThunk('appTickets/fileUpload', async (FormData: FormData) => {
  try {
    const response = await axiosInstance.post(`/tickets/file`, FormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    return {
      sucess: false,
      data: error,
    };
  }
});

export const getTickets = createAsyncThunk('appTickets/getTickets', async () => {
  try {
    const response = await axiosInstance.get(`${ticketUrl}/list`);

    return { status: response.status, data: response.data?.data as ListTickets };
  } catch (error) {
    // @ts-ignore
    return { status: 500, data: error?.data?.data };
  }
});

export const getTicketById = createAsyncThunk('appTickets/getTicketById', async (id: any) => {
  const response = await axiosInstance.get(`${ticketUrl}/${id}`);

  return response.data;
});

export const createTicket = createAsyncThunk('appTickets/createTicket', async (data: any) => {
  try {
    const response = await axiosInstance.post(`/tickets`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    return {
      sucess: false,
      data: error,
    };
  }
});

export const replyTicket = createAsyncThunk('appTickets/replyTicket', async (data: { id: any; [key: string]: number | string }) => {
  const { id, ...requestData } = data;
  const response = await axiosInstance.post(`${ticketUrl}/${id?.id}/reply`, requestData);

  return response.data;
});

export const appTicketsSlice = createSlice({
  name: 'appTickets',
  initialState: {
    data: [],
  },
  reducers: {},
});

export default appTicketsSlice.reducer;
